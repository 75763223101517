export const data = [
	{
		user_key: "user:users.user-lists",
		russian: "список пользователей",
		arabic: ".قوائم المستخدمين",
		english: "user lists",
	},
	{
		user_key: "user:users.user-first-name",
		russian: "имя пользователя",
		arabic: "اسم المستخدم الأول",
		english: "user first name",
	},
	{
		user_key: "user:users.user-last-name",
		russian: "фамилия пользователя",
		arabic: "اسم المستخدم الأخير",
		english: "user last name",
	},
	{
		user_key: "user:users.user-login",
		russian: "логин пользователя",
		arabic: "دخول المستخدم",
		english: "user login",
	},
	{
		user_key: "user:users.user-add-product",
		russian: "добавить продукт",
		arabic: "أضف المنتج",
		english: "add product",
	},
	{
		user_key: "user:users.user-orders-list",
		russian: "упорядоченный список",
		arabic: "قائمة الأوامر",
		english: "orders list",
	},
	{
		user_key: "user:users.user-coupon-list",
		russian: "список купонов",
		arabic: "قائمة القسائم",
		english: "coupon list",
	},
	{
		user_key: "user:users.user-new-password",
		russian: "пользователя новый пароль",
		arabic: ".مستخدم جديد كلمة مرور",
		english: "user new password",
	},
	{
		user_key: "user:users.user-reset-password",
		russian: "сброс пароля",
		arabic: "أعد ضبط كلمة السر",
		english: "user reset password",
	},
	{
		user_key: "user:users.user-email-id",
		russian: "идентификатор электронной почты",
		arabic: "هوية البريد الإلكتروني",
		english: "email id",
	},
	{
		user_key: "user:users.user-roles",
		russian: "роль пользователя",
		arabic: "أدوار المستخدم",
		english: "user roles",
	},
	{
		user_key: "user:users.user-profile-information",
		russian: "информация профиля",
		arabic: "معلومات التوصيف",
		english: "profile information",
	},
	{
		user_key: "admin:admin.button.delete",
		russian: "удалить",
		arabic: "احذف",
		english: "delete",
	},
	{
		user_key: "admin:admin.button.save",
		russian: "спасать",
		arabic: "احفظ",
		english: "save",
	},
	{
		user_key: "admin:admin.button.edit",
		russian: "редактировать",
		arabic: "حرر",
		english: "edit",
	},
	{
		user_key: "admin:admin.tabs.general",
		russian: "главные вкладки",
		arabic: "الألسنة العامة",
		english: "general tabs",
	},
	{
		user_key: "category:categories.physical",
		russian: "физический",
		arabic: "جسدي",
		english: "physical",
	},
	{
		user_key: "category:categories.digital",
		russian: "цифровой",
		arabic: "رقمي",
		english: "digital",
	},
	{
		user_key: "category:categories.sub.category",
		russian: "промежуточная категория",
		arabic: "الفئة الفرعيةر",
		english: "sub category",
	},
	{
		user_key: "media:media.dropzone",
		russian: "зона сброса",
		arabic: "منطقة الإسقاط",
		english: "dropzone",
	},
	{
		user_key: "vendor:vendors.vendor-lists",
		russian: "списки поставщиков",
		arabic: "قوائم البائعين",
		english: "vendor lists",
	},
	{
		user_key: "vendor:vendors.vendor-first-name",
		russian: "имя поставщика",
		arabic: "اسم البائع الأول",
		english: "vendor first name",
	},
	{
		user_key: "vendor:vendors.vendor-last-name",
		russian: "фамилия поставщика",
		arabic: "اسم عائلة البائع",
		english: "vendor last name",
	},
	{
		user_key: "vendor:vendors.vendor-login",
		russian: "вход поставщика",
		arabic: "تسجيل دخول البائعين",
		english: "vendor login",
	},
	{
		user_key: "vendor:vendors.vendor-add-product",
		russian: "добавить продукт",
		arabic: "أضف المنتج",
		english: "add product",
	},
	{
		user_key: "vendor:vendors.vendor-orders-list",
		russian: "упорядоченный список",
		arabic: "قائمة الأوامر",
		english: "orders list",
	},
	{
		user_key: "user:users.user-coupon-list",
		russian: "список купонов",
		arabic: "قائمة القسائم",
		english: "coupon list",
	},
	{
		user_key: "vendor:vendors.vendor-new-password",
		russian: "новый пароль поставщика",
		arabic: "كلمة مرور جديدة للبائع",
		english: "vendor new password",
	},
	{
		user_key: "vendor:vendors.vendor-reset-password",
		russian: "пароль сброса поставщика",
		arabic: "إعادة تعيين كلمة السر للبائع",
		english: "vendor reset password",
	},
	{
		user_key: "vendor:vendors.vendor-email-id",
		russian: "идентификатор электронной почты",
		arabic: "هوية البريد الإلكتروني",
		english: "email id",
	},
	{
		user_key: "vendor:vendors.vendor-roles",
		russian: "роли поставщиков",
		arabic: "باء-أدوار البائعين",
		english: "vendor roles",
	},
	{
		user_key: "vendor:vendors.vendor-profile-information",
		russian: "информация профиля",
		arabic: "معلومات التوصيف",
		english: "profile information",
	},
	{
		user_key: "admin:admin.tabs.general",
		russian: "главные вкладки",
		arabic: "الألسنة العامة",
		english: "general tabs",
	},
	{
		user_key: "media:media.dropzone",
		russian: "зона сброса",
		arabic: "منطقة الإسقاط",
		english: "dropzone",
	},
	{
		user_key: "category:categories.digital",
		russian: "цифровой",
		arabic: "رقمي",
		english: "digital",
	},
	{
		user_key: "category:categories.sub.category",
		russian: "промежуточная категория",
		arabic: "الفئة الفرعيةر",
		english: "sub category",
	},
	{
		user_key: "category:categories.physical",
		russian: "физический",
		arabic: "جسدي",
		english: "physical",
	},
];
export default data;
